var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var CaseInfoService = /** @class */ (function () {
    function CaseInfoService() {
    }
    /**
     * 部门待分配
     */
    CaseInfoService.prototype.queryOrganizationDistributeCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.queryOrganizationDistributeCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                caseNumber: data.caseNumber,
                phone: data.phone,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal.min,
                overdueAmtTotalEnd: data.overdueAmtTotal.max,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                delegationDateStart: data.delegationDate.length > 0 ? data.delegationDate[0] : "",
                delegationDateEnd: data.delegationDate.length > 0 ? data.delegationDate[1] : "",
                comment: data.comment,
                departCode: data.departCode,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                city: data.city,
                endCaseDateStart: data.endCaseDate[0],
                endCaseDateEnd: data.endCaseDate[1],
                caseType: data.caseType,
            },
            page: page,
            sort: sort,
        });
    };
    /**
     * 公共案件池查询
     */
    CaseInfoService.prototype.queryPublicCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.queryPublicCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                caseNumber: data.caseNumber,
                caseType: data.caseType,
                cardNo: data.cardNo,
                phone: data.phone,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal ? data.overdueAmtTotal.min : null,
                overdueAmtTotalEnd: data.overdueAmtTotal ? data.overdueAmtTotal.max : null,
                leftAmtStart: data.leftAmt ? data.leftAmt.min : null,
                leftAmtEnd: data.leftAmt ? data.leftAmt.max : null,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                overduePeriodsStart: data.overduePeriods ? data.overduePeriods.min : null,
                overduePeriodsEnd: data.overduePeriods ? data.overduePeriods.max : null,
                overdueDaysStart: data.overdueDays ? data.overdueDays.min : null,
                overdueDaysEnd: data.overdueDays ? data.overdueDays.max : null,
                city: data.city,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
        });
    };
    /**
     * 停调案件池查询
     */
    CaseInfoService.prototype.queryStopCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.queryStopCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                caseType: data.caseType,
                phone: data.phone,
                caseNumber: data.caseNumber,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal.min,
                overdueAmtTotalEnd: data.overdueAmtTotal.max,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                city: data.city,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                stopTimeStart: data.stopTime ? data.stopTime[0] : null,
                stopTimeEnd: data.stopTime ? data.stopTime[1] : null,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
        });
    };
    /**
     * 在调案件池查询
     */
    CaseInfoService.prototype.queryCollectingCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.queryCollectingCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                phone: data.phone,
                caseNumber: data.caseNumber,
                stage: data.stage,
                payStatus: data.payStatus,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal ? data.overdueAmtTotal.min : null,
                overdueAmtTotalEnd: data.overdueAmtTotal ? data.overdueAmtTotal.max : null,
                leftAmtStart: data.leftAmt ? data.leftAmt.min : null,
                leftAmtEnd: data.leftAmt ? data.leftAmt.max : null,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                overduePeriodsStart: data.overduePeriods ? data.overduePeriods.min : null,
                overduePeriodsEnd: data.overduePeriods ? data.overduePeriods.max : null,
                overdueDaysStart: data.overdueDays ? data.overdueDays.min : null,
                overdueDaysEnd: data.overdueDays ? data.overdueDays.max : null,
                comment: data.comment,
                collectorName: data.collectorName,
                departCode: data.departCode,
                city: data.city,
                followTimeStart: data.followTime ? data.followTime[0] : null,
                followTimeEnd: data.followTime ? data.followTime[1] : null,
                leaveFlag: data.leaveFlag,
                caseType: data.caseType,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
        });
    };
    /**
     * 结清案件池查询
     */
    CaseInfoService.prototype.querySettleCases = function (data, page, sort) {
        return this.netService.send({
            server: businessService.caseInfoController.querySettleCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                phone: data.phone,
                caseNumber: data.caseNumber,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                caseType: data.caseType,
                overdueAmtTotalStart: data.overdueAmtTotal.min,
                overdueAmtTotalEnd: data.overdueAmtTotal.max,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                paymentStatus: data.paymentStatus,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                comment: data.comment,
                collectorName: data.collectorName,
                departCode: data.departCode,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                city: data.city,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                stopTimeStart: data.stopTime ? data.stopTime[0] : null,
                stopTimeEnd: data.stopTime ? data.stopTime[1] : null,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                leaveFlag: data.leaveFlag,
            },
            page: page,
            sort: sort,
        });
    };
    /**
     * 案件停调
     */
    CaseInfoService.prototype.stopCases = function (data) {
        return this.netService.send({
            server: businessService.caseInfoController.stopCases,
            data: {
                ids: data,
            },
        });
    };
    /**
     * 案件复调
     */
    CaseInfoService.prototype.backCollectCasePool = function (data) {
        return this.netService.send({
            server: businessService.caseInfoController.backCollectCasePool,
            data: {
                ids: data,
            },
        });
    };
    /**
     * 案件退案
     */
    CaseInfoService.prototype.changeRetireCases = function (personalIds, caseIds) {
        return this.netService.send({
            server: businessService.caseInfoController.changeRetireCases,
            data: {
                personalIds: personalIds,
                caseIds: caseIds,
            },
        });
    };
    /**
     * 案件批次号查询
     */
    CaseInfoService.prototype.getBatchNumber = function (poolName) {
        return this.netService.send({
            server: businessService.caseInfoController.getBatchNumber,
            data: {
                poolName: poolName,
            },
        });
    };
    /**
     * 刷新退案时间
     */
    CaseInfoService.prototype.updateCaseEndDate = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.updateCaseEndDate,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询客户信息
     */
    CaseInfoService.prototype.getPersonalById = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.getPersonalById,
            data: {
                personalId: data,
            },
            loading: loading,
        });
    };
    /**
     * 更新案件信息
     */
    CaseInfoService.prototype.updateCaseMessage = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.updateCaseMessage,
            data: data,
            loading: loading,
        });
    };
    /**
     * 通过id查询案件信息
     */
    CaseInfoService.prototype.getPrintMessage = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.getPrintMessage,
            data: {
                caseIds: data.caseIds,
            },
            loading: loading,
        });
    };
    /**
     * 快捷菜单查询案件信息
     */
    CaseInfoService.prototype.caseCollectionStatus = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.caseCollectionStatus,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 根据客户信息查询共债案件批次
     */
    CaseInfoService.prototype.searchDebtBatch = function (data) {
        return this.netService.send({
            server: businessService.caseInfoController.searchDebtBatch,
            data: data,
        });
    };
    /**
     * 根据客户信息查询共债案件卡号
     */
    CaseInfoService.prototype.searchDebtCardInfo = function (data) {
        return this.netService.send({
            server: businessService.caseInfoController.searchDebtCardInfo,
            data: data,
        });
    };
    /**
     * 根据客户信息查询公债案件的数量
     */
    CaseInfoService.prototype.searchDebtCaseCount = function (data) {
        return this.netService.send({
            server: businessService.caseInfoController.searchDebtCaseCount,
            data: data,
        });
    };
    /**
     * 更改调收方式
     */
    CaseInfoService.prototype.changeCaseType = function (data) {
        return this.netService.send({
            server: businessService.caseInfoController.changeCaseType,
            data: data,
        });
    };
    /**
     * 流转待分配查询
     */
    CaseInfoService.prototype.getAllCirculationCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.getAllCirculationCase,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                cardNo: data.cardNo,
                caseNumber: data.caseNumber,
                phone: data.phone,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal.min,
                overdueAmtTotalEnd: data.overdueAmtTotal.max,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                comment: data.comment,
                departCode: data.departCode,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                city: data.city,
                endCaseDateStart: data.endCaseDate[0],
                endCaseDateEnd: data.endCaseDate[1],
                caseType: data.caseType,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 检查案件是否存在协调申请和协调案件
     */
    CaseInfoService.prototype.checkCase = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoController.checkCase,
            data: data,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseInfoService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "queryOrganizationDistributeCases", null);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "queryPublicCases", null);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "queryStopCases", null);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "queryCollectingCases", null);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "querySettleCases", null);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "getAllCirculationCase", null);
    __decorate([
        Debounce()
    ], CaseInfoService.prototype, "checkCase", null);
    return CaseInfoService;
}());
export { CaseInfoService };
